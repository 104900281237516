<template>
  <div style="height: 200px">
    <v-chart
      class="chart"
      :option="opts"
      id="time-distribution-container"
      ref="graph"
      :theme="theme"
    />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LinesChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import "../themes/roma.js";
import "../themes/dark.js";
import DataService from "../lib/DataService";
import * as utils from "../lib/Utilities.js";

use([
  CanvasRenderer,
  LinesChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
]);

export default {
  name: "Charts",
  components: {
    VChart,
  },
  async mounted() {
    this.setTheme();
    this.categoryDistribution = await DataService.categoryDistribution();
    this.categoryIndex = await DataService.categoryIndex();
    this.opts.xAxis.data = this.categoryDistribution["range"];
    this.setGraph();
  },
  props: {
    categories: Array,
    mainTheme: String,
  },
  methods: {
    setTheme() {
      if (this.mainTheme == "dark") {
        this.theme = "dark";
      } else {
        this.theme = "roma";
      }
      this.$forceUpdate();
    },
    setGraph() {
      const legend = [];
      const series = [];
      this.$refs.graph.clear();
      this.opts.legend.data = [];
      this.opts.series = [];
      this.categories.forEach((element, index) => {
        legend.push(this.categoryIndex[element.toString()]);
        series.push({
          areaStyle: {
            opacity: 0,
          },
          lineStyle: {
            opacity: 0,
          },
          tooltip: {
            show: false,
          },
          itemStyle: {
            color: utils.chartColorPalette[this.theme][index],
          },
          data: this.categoryDistribution[element.toString()]["upperBound"],
          type: "line",
          smooth: true,
          name: this.categoryIndex[element.toString()] + " lower",
          symbol: "none",
          stack: "bounds" + index,
          legendHoverLink: false,
        });
        series.push({
          stack: "bounds" + index,
          areaStyle: {
            opacity: 0.5,
          },
          data: this.categoryDistribution[element.toString()]["mean"],
          type: "line",
          smooth: true,
          name: this.categoryIndex[element.toString()] + " (certainty mean)",
          symbol: "none",
          lineStyle: {
            opacity: 1,
          },
          itemStyle: {
            color: utils.chartColorPalette[this.theme][index],
          },
        });
        series.push({
          stack: "bounds" + index,
          areaStyle: {
            opacity: 0.5,
          },
          lineStyle: {
            opacity: 0,
          },
          data: this.categoryDistribution[element.toString()]["lowerBound"],
          type: "line",
          smooth: true,
          name: this.categoryIndex[element.toString()] + " upper",
          symbol: "none",
          itemStyle: {
            color: utils.chartColorPalette[index],
          },
          legendHoverLink: false,
          tooltip: {
            show: false,
          },
        });
      });
      this.opts.legend.data = legend;
      console.log(series);
      this.opts.series = series;
    },
  },
  watch: {
    mainTheme() {
      this.setTheme();
    },
    categories() {
      this.setGraph();
    },
  },
  data() {
    return {
      theme: "roma",
      categoryDistribution: [],
      categoryIndex: [],
      opts: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          left: 50,
          top: 10,
          right: 50,
        },
        legend: {
          data: [],
          top: -5,
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
          position: "right",
        },
        series: [],
      },
    };
  },
};
</script>

<style scoped></style>
