<template>
  <div>
    <div class="w-full flex h-full place-content-center mb-6" v-if="!loaded">
      <i class="fa-solid fa-spinner fa-spin fa-xl mt-6 min-h-full"></i>
    </div>
    <img
      ref="pic"
      :src="urls[baseSize]"
      class="w-full rounded shadow-md"
      :class="{ 'cursor-pointer': !error }"
      @error="errorImage"
      v-show="loaded"
    />
  </div>
</template>

<script>
import Viewer from "viewerjs";
import * as utils from "../lib/Utilities.js";

export default {
  name: "ResourceImage",
  components: {},
  data() {
    return {
      loaded: false,
      urls: {
        pre: "/images/spinning_wheel.gif",
        thm: "/images/spinning_wheel.gif",
      },
      error: false,
      viewer: null,
    };
  },
  props: {
    resourceId: Number,
    baseSize: String,
  },
  mounted() {
    this.getLink(this.resourceId);
  },
  watch: {
    resourceId() {
      this.urls.pre = "/images/spinning_wheel.gif";
      this.urls.thm = "/images/spinning_wheel.gif";
      this.getLink(this.resourceId);
    },
    urls(values) {
      if ((values.scr || values.original) && !this.error) {
        const image = this.$refs.pic;
        var large = values.scr;
        if (!large) {
          large = values.original;
        }
        this.viewer = new Viewer(image, {
          url() {
            return large;
          },
          navbar: false,
          title: false,
          toolbar: {
            zoomIn: true,
            zoomOut: true,
            oneToOne: true,
            reset: true,
            prev: false,
            play: {
              show: false,
              size: "large",
            },
            next: false,
            rotateLeft: true,
            rotateRight: true,
            flipHorizontal: true,
            flipVertical: true,
          },
        });
      }
    },
  },
  //mounted() {},
  methods: {
    errorImage(event) {
      this.error = true;
      event.target.src = "/images/placeholder.png";
      if (this.viewer) {
        this.viewer.destroy();
      }
    },
    async getLink(resourceId) {
      const call = `user=${localStorage.username}&function=get_resource_all_image_sizes&resource=${resourceId}`;
      const signed = await utils.hash(localStorage.sessionKey + call);
      try {
        const response = await this.axios.get(
          `${utils.host}/?${call}&sign=${signed}&authmode=sessionkey`
        );
        this.urls = response.data.reduce(
          (o, key) => ({ ...o, [key.size_code]: key.url }),
          {}
        );
        this.loaded = true;
      } catch (error) {
        if (error.response.status == 401) {
          console.log(error);
          localStorage.sessionKey = "";
          localStorage.username = "";
          this.$emit("signout");
        }
      }
    },
  },
};
</script>

<style scoped>
@import "~viewerjs/dist/viewer.css";
</style>
