import Vue from "vue";

class DataService {
  constructor() {}
  async resources() {
    const response = await Vue.axios.get("/json/resources_geojson.json");
    return response.data;
  }

  async categoryTree() {
    const response = await Vue.axios.get("/json/category_tree.json");
    return response.data;
  }

  async categoryIndex() {
    const response = await Vue.axios.get("/json/category_index.json");
    return response.data;
  }

  async categoryDistribution() {
    const response = await Vue.axios.get("/json/category_distribution.json");
    return response.data;
  }
}

export default new DataService();
