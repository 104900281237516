<template>
  <v-chart
    class="chart dark:bg-slate-800"
    :option="option"
    autoresize
    :theme="theme"
    id="charts-container"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import "../themes/roma.js";
import "../themes/dark.js";
import DataService from "../lib/DataService";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "Charts",
  components: {
    VChart,
  },
  props: {
    resources: Array,
    categories: Array,
    mainTheme: String,
  },
  methods: {
    setTheme() {
      if (this.mainTheme == "dark") {
        this.theme = "dark";
      } else {
        this.theme = "roma";
      }
      this.$forceUpdate();
    },
    calculateValues() {
      let legend = [];
      let data = [];
      this.categories.forEach((element) => {
        let count = this.resources.filter(
          (el) => el.properties.category_ids.indexOf(element) >= 0
        ).length;
        data.push({
          name: this.categoryIndex[element.toString()],
          value: count,
        });
        legend.push(this.categoryIndex[element.toString()]);
      });
      this.option.series[0].data = data;
      this.option.legend.data = legend;
    },
  },
  watch: {
    mainTheme() {
      this.setTheme();
    },
    categories() {
      this.calculateValues();
    },
    resources() {
      this.calculateValues();
    },
  },
  async mounted() {
    this.setTheme();
    this.categoryIndex = await DataService.categoryIndex();
    this.calculateValues();
  },
  data() {
    return {
      theme: "roma",
      categoryIndex: [],
      option: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "horizontal",
          left: "left",
          data: [],
        },

        series: [
          {
            itemStyle: {
              normal: {
                borderRadius: 2,
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            name: "Category",
            stillShowZeroSum: false,
            type: "pie",
            radius: "75%",
            center: ["50%", "60%"],
            data: [],
            animation: false,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 400px; /* or e.g. 400px */
}
</style>
