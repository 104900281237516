import * as utils from "../Utilities";
import axios from "axios";

export default class HistoricalBordersLayer {
  constructor(year) {
    this.visible = false;
    this.year = year;
    this.url = `https://raw.githubusercontent.com/aourednik/historical-basemaps/master/geojson/world_${this.year}.geojson`;
    this.layers = [
      `fills${year}`,
      `borders${year}`,
      `labels-large${year}`,
      `labels-small${year}`,
    ];
    this.getSource();
    this.data = null;
    this.fillData = null;
    this.labelData = null;
  }
  async getSource() {
    const response = await axios.get(this.url);
    this.data = response.data;
    this.fillData = utils.colorPolygons(this.data);
    this.labelData = utils.getCentroids(this.data);
  }

  async addSource(map) {
    map.addSource(`geo${this.year}`, {
      type: "geojson",
      data: this.fillData,
      attribution:
        "Historical world borders collated by <a href='https://ourednik.info/' target='_blank'>André Ourednik</a> from <a href='https://github.com/aourednik/historical-basemaps#historical-boundaries-of-world-countries-and-cultural-regions' target='_blank'>various sources</a>.",
    });
    map.addSource(`labels${this.year}`, {
      type: "geojson",
      data: this.labelData,
    });
  }

  show(map) {
    this.layers.forEach((layer) => {
      map.setLayoutProperty(layer, "visibility", "visible");
    });
  }

  hide(map) {
    this.layers.forEach((layer) => {
      map.setLayoutProperty(layer, "visibility", "none");
    });
  }

  add(map) {
    map.addLayer({
      id: `fills${this.year}`,
      type: "fill",
      source: `geo${this.year}`,
      paint: {
        "fill-color": { type: "identity", property: "color" },
        "fill-opacity": 0.4,
      },
      layout: {
        visibility: "none",
      },
    });
    map.addLayer({
      id: `borders${this.year}`,
      type: "line",
      source: `geo${this.year}`,
      layout: {
        visibility: "none",
      },
      paint: {
        "line-color": "rgba(51, 51, 51, 1)",
        "line-width": {
          stops: [
            [4, 1],
            [5, 1.5],
            [6, 1.5],
          ],
        },
      },
    });

    map.addLayer({
      id: `labels-small${this.year}`,
      type: "symbol",
      source: `labels${this.year}`,
      minzoom: 4,
      maxzoom: 18,
      filter: ["<", ["get", "text_size"], 12],
      layout: {
        "text-size": { type: "identity", property: "text_size" },
        "text-font": ["Klokantech Noto Sans Regular"],
        visibility: "none",
        "text-offset": [0, 0.5],
        "icon-size": 1,
        "text-anchor": "top",
        "text-field": "{name}",
        "text-max-width": 8,
        "text-allow-overlap": true,
      },
      paint: {
        "text-color": "rgba(255, 255, 255, 1)",
        "text-halo-width": 1.4,
        "text-halo-color": "rgba(51, 51, 51, 1)",
        "text-halo-blur": 0,
      },
    });

    map.addLayer({
      id: `labels-large${this.year}`,
      type: "symbol",
      source: `labels${this.year}`,
      minzoom: 2,
      maxzoom: 4,
      filter: [">=", ["get", "text_size"], 12],
      layout: {
        "text-size": { type: "identity", property: "text_size" },
        "text-font": ["Klokantech Noto Sans Regular"],
        visibility: "none",
        "text-offset": [0, 0.5],
        "icon-size": 1,
        "text-anchor": "top",
        "text-field": "{name}",
        "text-max-width": 8,
        "text-allow-overlap": true,
      },
      paint: {
        "text-color": "rgba(255, 255, 255, 1)",
        "text-halo-width": 1.4,
        "text-halo-color": "rgba(51, 51, 51, 1)",
        "text-halo-blur": 0,
      },
    });
  }
}
