<template>
  <div>
    <div class="grid grid-cols-12 w-full max-h-fit">
      <!-- Main modal -->
      <div
        id="aboutModal"
        data-modal-backdrop="static"
        tabindex="-1"
        aria-hidden="true"
        class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
      >
        <div class="relative w-full h-full max-w-4xl md:h-auto">
          <!-- Modal content -->
          <div
            class="relative bg-white rounded-lg shadow dark:bg-dark-charcoal"
          >
            <!-- Modal header -->
            <div
              class="flex items-start justify-between p-3 pl-6 pt-4 border-b rounded-t bg-mpiwg-green dark:bg-charcoal dark:border-light-charcoal"
            >
              <h3 class="text-xl text-white dark:text-white">
                About this Platform
              </h3>
              <button
                type="button"
                class="text-white bg-transparent hover:text-mpiwg-light-green rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:text-white dark:text-neutral-400"
                id="closeButton"
              >
                <svg
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <!-- Modal body -->
            <div class="p-6 space-y-6">
              <p class="text-gray-500 dark:text-white">
                <iframe
                  v-if="modalVisible"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/uVFKBN1-P6U"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  class="w-full"
                  ref="YTVideo"
                ></iframe>
              </p>
              <p class="text-neutral-900 dark:text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                vehicula et massa non porttitor. In volutpat dui eget ligula
                gravida, id congue velit molestie. Integer facilisis ex eget
                feugiat sollicitudin. Aliquam sed volutpat sem. Donec iaculis
                fringilla mi sit amet laoreet. In vitae gravida nibh. Quisque
                congue nisl sit amet ante iaculis ullamcorper. Ut nisl sem,
                lobortis non nisi vitae, commodo aliquet ante. Donec diam arcu,
                ullamcorper quis ipsum eu, lobortis vulputate nisi. Praesent
                malesuada urna in ipsum elementum, eget viverra tellus
                imperdiet. Suspendisse at malesuada tellus, sed blandit elit.
                Pellentesque congue rhoncus ex, sit amet laoreet augue malesuada
                nec. Vivamus lacinia purus mauris, a mattis justo vestibulum
                eget. Quisque et mollis urna. Cras quis magna in orci finibus
                accumsan. Sed dapibus, ligula vitae semper sodales, orci erat
                dapibus dolor, ut cursus velit mi a elit.
              </p>
              <p class="text-neutral-900 dark:text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                vehicula et massa non porttitor. In volutpat dui eget ligula
                gravida, id congue velit molestie. Integer facilisis ex eget
                feugiat sollicitudin. Aliquam sed volutpat sem. Donec iaculis
                fringilla mi sit amet laoreet. In vitae gravida nibh. Quisque
                congue nisl sit amet ante iaculis ullamcorper. Ut nisl sem,
                lobortis non nisi vitae, commodo aliquet ante. Donec diam arcu,
                ullamcorper quis ipsum eu, lobortis vulputate nisi. Praesent
                malesuada urna in ipsum elementum, eget viverra tellus
                imperdiet. Suspendisse at malesuada tellus, sed blandit elit.
                Pellentesque congue rhoncus ex, sit amet laoreet augue malesuada
                nec. Vivamus lacinia purus mauris, a mattis justo vestibulum
                eget. Quisque et mollis urna. Cras quis magna in orci finibus
                accumsan. Sed dapibus, ligula vitae semper sodales, orci erat
                dapibus dolor, ut cursus velit mi a elit.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-span-12 h-13 text-left shadow grid grid-cols-12 dark:shadow-none"
      >
        <a
          href="/"
          class="col-span-3 p-2 place-items-center pt-2 dark:bg-dark-charcoal"
        >
          <img
            class="h-8 inline ml-3 dark:invert"
            src="images/mpiwg_logo_single.png"
          />
          <span class="inline ml-3 pt-1 text-mpiwg-green dark:text-white"
            >Visualizing the Heavens</span
          >
        </a>
        <div
          class="col-span-5 place-items-center p-2 pt-2 bg-mpiwg-green dark:bg-lighter-charcoal"
        ></div>
        <div
          class="col-span-4 place-items-end text-right p-2 bg-mpiwg-green pr-0 dark:bg-lighter-charcoal w-full"
        >
          <button
            class="text-white text-xs border-r pr-2 pl-2 dark:border-charcoal dark:text-charcoal hover:text-mpiwg-light-green"
            @click="toggleTheme"
          >
            <i
              class="fa-solid fa-sm"
              :class="mainTheme == 'dark' ? 'fa-sun' : 'fa-moon'"
              aria-hidden="true"
            ></i>
          </button>
          <button
            id="aboutButton"
            class="text-white text-xs border-r pr-2 pl-2 dark:border-charcoal dark:text-charcoal dark:hover:underline hover:text-mpiwg-light-green"
            data-modal-target="aboutModal"
            data-modal-toggle="aboutModal"
          >
            about
          </button>
          <button
            class="text-white text-xs py-2 px-4 pl-2 dark:text-charcoal cursor-default"
          >
            <i class="fa fa-user mr-1 fa-sm" aria-hidden="true"></i
            >{{ username }}
          </button>
          <button
            class="bg-white hover:bg-mpiwg-light-green text-black text-xs py-2 px-4 border border-gray-400 rounded shadow mr-2 dark:bg-charcoal dark:text-white dark:hover:text-white dark:hover:bg-light-charcoal"
            @click="$emit('signout')"
          >
            <i class="fa fa-sign-out" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="col-span-3 p-4 row-span-2 w-full pt-6">
        <div class="text-center dark:text-white text-base mb-6">
          <span>Showing {{ filteredResources.length }} items</span>
        </div>
        <div>
          <label class="dark:text-white mb-4"
            >Category filter<i
              class="fa-solid fa-circle-info cursor-help ml-2 text-neutral-400 dark:hover:text-neutral-200 hover:text-neutral-600"
              data-tooltip-target="tooltip-default"
              data-tooltip-placement="right"
            ></i>
            <div
              id="tooltip-default"
              role="tooltip"
              class="inline-block w-80 absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-neutral-500 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 dark:bg-neutral-900"
            >
              <b>Filter by category</b> <br />Select the "or" option to filter
              resources that belong to any of the selected categories.<br />
              The "and" option will filter resources that only belong to each of
              the selected categories.
            </div></label
          >
          <treeselect
            :disable-branch-nodes="true"
            search-nested
            v-model="selectedCategories"
            :multiple="true"
            :options="options"
            :auto-focus="true"
            placeholder="Select categories..."
            class="mt-2"
          />
        </div>
        <div class="and-or form-check-inline">
          <label for="inclusion2" class="inline-block"
            ><input
              type="radio"
              id="inclusion2"
              name="inclusion"
              value="or"
              v-model="andOr"
              @change="updateResources"
              class="inline-block"
            />
            or</label
          >
          <label for="inclusion1" class="inline-block"
            ><input
              type="radio"
              id="inclusion1"
              name="inclusion"
              value="and"
              v-model="andOr"
              @change="updateResources"
              class="inline-block"
            />
            and</label
          >
          <div class="mt-4">
            <label class="dark:text-white mb-4"
              >Text filter<i
                class="fa-solid fa-circle-info ml-2 cursor-help text-neutral-400 dark:hover:text-neutral-200 hover:text-neutral-600"
                data-tooltip-target="tooltip-text-filter"
                data-tooltip-placement="right"
              ></i>
              <div
                id="tooltip-text-filter"
                role="tooltip"
                class="inline-block w-80 absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-neutral-500 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-neutral-900"
              >
                <b>Text Filter</b> <br />The text filter will filter the
                resources that feature the inputted text in the resource's title
                or description.
              </div></label
            >
            <input
              type="text"
              placeholder="Search text..."
              v-model="textSearch"
              @change="updateResources"
              style="height: 36px"
              class="mt-2 text-search w-full p-1 pl-2 border border-fancy-grey pt-2 pb-2 rounded dark:bg-dark-charcoal dark:border dark:border-white"
            />
          </div>
        </div>
        <div class="mt-4">
          <label class="dark:text-white mb-4"
            >Collection filter<i
              class="fa-solid fa-circle-info ml-2 cursor-help text-neutral-400 dark:hover:text-neutral-200 hover:text-neutral-600"
              data-tooltip-target="tooltip-collection-filter"
              data-tooltip-placement="right"
            ></i>
            <div
              id="tooltip-collection-filter"
              role="tooltip"
              class="inline-block w-80 invisible z-10 py-2 px-3 m-6 text-sm font-medium text-white bg-neutral-500 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-neutral-900"
            >
              <b>Filter by Collection</b><br />The collection filter will filter
              the resources that belong to the selected collection.
            </div></label
          >
          <CollectionManager @collectionSelected="filterByCollection" />
          <div class="mt-4">
            <label class="dark:text-white mb-4"
              >Geo Uncertainty Filter<i
                class="fa-solid fa-circle-info ml-2 cursor-help text-neutral-400 dark:hover:text-neutral-200 hover:text-neutral-600"
                data-tooltip-target="tooltip-geo-fuzz"
                data-tooltip-placement="right"
              ></i>
              <div
                id="tooltip-geo-fuzz"
                role="tooltip"
                class="inline-block w-80 absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-neutral-500 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-neutral-900"
              >
                <b>Geographical Uncertainty</b> <br />Filter items by geo
                uncertainty. We recommend using the <i>Heatmap</i> layer when
                visualizing uncertain geographical trends.
              </div></label
            ><select
              v-model="geofuzzFilter"
              @change="updateResources($event)"
              class="mb-4 mt-2 w-full p-1 pl-2 pr-0 h-9 border border-fancy-grey rounded dark:bg-dark-charcoal dark:border dark:border-white dark:text-white appearance-none"
            >
              <option
                v-for="option in geofuzzFilterValues"
                :key="option"
                :value="option"
              >
                {{ option === null ? "Any" : capitalize(option) }}
              </option>
            </select>
          </div>
        </div>
        <br />
        <Charts
          :categories="selectedCategories"
          :resources="filteredResources"
          :mainTheme="mainTheme"
          v-if="selectedCategories.length > 1"
        />
      </div>
      <div
        id="slideover-container"
        class="w-full h-full fixed inset-0 invisible z-50"
      >
        <div
          @click="toggleSlideover"
          id="slideover-bg"
          class="w-full h-full duration-500 ease-out transition-all inset-0 absolute bg-gray-900 opacity-0 dark:bg-charcoal"
        ></div>
        <div
          id="slideover"
          class="w-4/12 bg-white h-full absolute right-0 duration-300 ease-out transition-all translate-x-full dark:bg-dark-charcoal dark:border-l dark:border-charcoal"
        >
          <div
            @click="toggleSlideover"
            class="fixed cursor-pointer text-mpiwg-green top-0 w-8 h-8 flex items-center justify-center right-0 dark:text-neutral-400 dark:hover:text-white hover:text-mpiwg-light-green"
          >
            <i class="fa-solid fa-xmark fa-xl"></i>
          </div>
          <SideShow :features="currentFeatures" />
        </div>
      </div>
      <div
        id="fs-map"
        class="col-span-9 bg-neutral-200 dark:bg-dark-charcoal"
        :class="{ 'max-h-0': !mapVisible }"
      >
        <Map
          :mapVisible="mapVisible"
          :resources="filteredResources"
          :categories="selectedCategories"
          :mainTheme="mainTheme"
          @draw="setPolygons"
          @clicked="setFeatures"
          @hexClicked="setFeaturesFromHex"
          @playSlider="toggleTimer()"
          @resetSlider="resetTimer()"
          v-if="sessionKey != ''"
          :class="{ invisible: !mapVisible }"
        />
        <div
          class="mapboxgl-ctrl-group mapboxgl-ctrl play-bar"
          :class="{ invisible: !mapVisible }"
        >
          <button
            type="button"
            class="text-black"
            :class="playButtonIcon"
            @click="toggleTimer()"
          ></button>
          <button
            type="button"
            class="text-black fa-solid fa-stop"
            @click="resetTimer()"
          ></button>
        </div>
        <div
          class="bg-mpiwg-light-brown pt-4 text-white border-t border-slate border-l border-b border-gray pb-14 dark:bg-charcoal dark:border-0"
        >
          <div
            id="time-selector"
            style="width: 900px; margin: auto"
            :class="{ hidden: !mapVisible }"
          >
            <br />
            <div>
              <VueSlider
                v-model="rangeValues"
                :min="-4000"
                :max="2020"
                :enable-cross="false"
                :tooltip="'always'"
                :tooltip-placement="'top'"
                :adsorb="true"
                :interval="20"
                :min-range="200"
                ref="slider"
                style="margin-top: -86px; margin-left: 50px; margin-right: 50px"
              ></VueSlider>
              <div v-if="selectedCategories.length > 0">
                <TimeDistribution
                  :mainTheme="mainTheme"
                  :categories="selectedCategories"
                  style="width: 100%; margin-top: 80px; margin-bottom: 10px"
                />
              </div>
            </div>
          </div>
          <div
            class="container pb-0 pt-0 mx-0 pb-2 min-w-full flex flex-col items-center"
          >
            <button
              class="text-neutral-700 dark:text-neutral-200 inline-flex items-center py-0 px-4 pt-0 bg-white hover:bg-neutral-100 text-xs border border-gray-400 rounded shadow mr-2 dark:border-light-charcoal dark:hover:bg-dark-charcoal dark:bg-light-charcoal"
              @click="toggleMap()"
            >
              <i v-if="mapVisible" class="fa-solid fa-caret-up"></i>
              <i v-else class="fa-solid fa-caret-down"></i>
            </button>
          </div>
          <div class="p-6 pt-4">
            <Grid :resources="filteredResources" :mapVisible="mapVisible" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component";
import { Modal, initTooltips } from "flowbite";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Charts from "./Charts.vue";
import TimeDistribution from "./TimeDistribution.vue";
import SideShow from "./SideShow.vue";
import Grid from "./Grid.vue";
import Map from "./Map.vue";
import * as utils from "../lib/Utilities";
import DataService from "../lib/DataService";
import CollectionManager from "./CollectionManager.vue";

export default {
  props: {
    sessionKey: String,
    theme: String,
  },
  name: "Visualizer",
  components: {
    Charts,
    VueSlider,
    Treeselect,
    TimeDistribution,
    SideShow,
    Map,
    Grid,
    CollectionManager,
  },
  async mounted() {
    initTooltips();
    const $buttonElement = document.querySelector("#aboutButton");
    const $closeButton = document.querySelector("#closeButton");
    const $modalElement = document.querySelector("#aboutModal");
    if ($modalElement) {
      const modal = new Modal($modalElement, {
        backdropClasses:
          "bg-light-charcoal bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
        onHide: () => {
          this.modalVisible = false;
        },
        onShow: () => {
          this.modalVisible = true;
        },
      });
      $buttonElement.addEventListener("click", () => modal.toggle());
      $closeButton.addEventListener("click", () => {
        modal.hide();
      });
      modal.show();
    }

    this.username = localStorage.username;
    const resourcesGeoJson = await DataService.resources();

    //this.geofuzzFilterValues = [
    //  ...new Set(
    //    resourcesGeoJson.features.map((item) => item.properties.geo_fuzz)
    //  ),
    //];

    this.geofuzzFilterValues = {
      0: null,
      1: "city",
      2: "place",
      3: "province",
      4: "country",
      5: "island",
      6: "land mass",
    };

    //this.resources = resourcesGeoJson.features.filter(
    //  (x) => x.properties.workflow_status == "active"
    //);
    this.resources = resourcesGeoJson.features;
    this.options = await DataService.categoryTree();
    this.updateResources();
  },
  data() {
    let theme = this.theme;
    return {
      mainTheme: theme,
      mapVisible: true,
      selectedCollection: "none",
      modalVisible: true,
      andOr: "or",
      username: "",
      textSearch: "",
      geofuzzFilter: null,
      geofuzzFilterValues: [],
      polygons: null,
      currentFeatures: [],
      toggleCluster: false,
      isRunning: false,
      interval: null,
      time: 0,
      playButtonIcon: "fa-solid fa-play",
      clusteringButtonText: "(off)",
      drawButtonText: "draw",
      options: [],
      rangeValues: [-4000, 2020],
      resources: [],
      selectedCategories: [],
      filteredResources: [],
    };
  },
  watch: {
    rangeValues() {
      this.updateResources();
    },
    selectedCategories() {
      this.updateResources();
    },
  },
  methods: {
    toggleMap() {
      this.mapVisible = !this.mapVisible;
    },
    capitalize(str) {
      if (!str) return "";
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async filterByCollection(selectedCollection) {
      const resourcesGeoJson = await DataService.resources();
      this.resources = resourcesGeoJson.features.filter(
        (x) => x.properties.workflow_status == "active"
      );
      this.selectedCollection = selectedCollection;
      if (this.selectedCollection.name != "none") {
        let filteredFeatures = [];
        for (let i = 0; i < this.selectedCollection.refs.length; i++) {
          for (let ii = 0; ii < this.resources.length; ii++) {
            if (
              this.selectedCollection.refs[i] ==
              this.resources[ii].properties.ref
            ) {
              filteredFeatures.push(this.resources[ii]);
            }
          }
        }
        this.resources = filteredFeatures;
      }
      this.updateResources();
    },
    toggleTheme() {
      if (this.mainTheme == "light") {
        this.mainTheme = "dark";
        document.documentElement.classList.add("dark");
      } else {
        this.mainTheme = "light";
        document.documentElement.classList.remove("dark");
      }
      localStorage.theme = this.mainTheme;
    },
    toggleSlideover() {
      document
        .getElementById("slideover-container")
        .classList.toggle("invisible");
      document.getElementById("slideover-bg").classList.toggle("opacity-0");
      document.getElementById("slideover-bg").classList.toggle("opacity-50");
      document.getElementById("slideover").classList.toggle("translate-x-full");
      document.getElementById("slideover").scroll({
        top: 0,
      });
    },
    setFeatures(features) {
      this.currentFeatures = features;
      this.toggleSlideover();
    },
    setFeaturesFromHex(featureIds) {
      this.currentFeatures = this.filteredResources.filter((x) =>
        featureIds.includes(x.properties.ref)
      );
      this.toggleSlideover();
    },
    setPolygons(polygons) {
      this.polygons = polygons;
      this.updateResources();
    },
    resetTimer() {
      if (this.isRunning) {
        this.toggleTimer();
      }
      this.rangeValues = [-4000, 2020];
    },
    toggleTimer() {
      if (this.isRunning) {
        this.playButtonIcon = "fa-solid fa-play";
        clearInterval(this.interval);
      } else {
        this.playButtonIcon = "fa-solid fa-pause";
        this.interval = setInterval(this.incrementTime, 100);
      }
      this.isRunning = !this.isRunning;
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1;
      let newRangeValues = this.rangeValues;

      if (newRangeValues[1] < 2020) {
        newRangeValues[1] = newRangeValues[1] + 10;
      } else {
        //newRangeValues[1] = 2500;
      }

      if (newRangeValues[0] < newRangeValues[1] - 200) {
        newRangeValues[0] = newRangeValues[0] + 10;
      } else {
        newRangeValues[0] = newRangeValues[1] - 200;
        this.toggleTimer();
      }

      this.updateResources();
      this.$refs.slider.setValue(newRangeValues);
    },
    updateResources() {
      let polyFilteredResources = utils.filterPointsFromPoly(
        this.resources,
        this.polygons
      );
      this.filteredResources = polyFilteredResources.filter((el) => {
        var regex = new RegExp("" + this.textSearch + "", "i");
        return (
          el.geometry.coordinates.length > 0 &&
          el.properties.description != null &&
          el.properties.name != null &&
          (regex.test(el.properties.description) ||
            regex.test(el.properties.name)) &&
          el.properties.start_date > this.rangeValues[0] &&
          el.properties.end_date < this.rangeValues[1]
        );
      });
      if (this.selectedCategories.length > 0) {
        this.filteredResources = this.filteredResources.filter((el) => {
          if (this.andOr == "and") {
            return this.selectedCategories.every(
              (r) => el.properties.category_ids.indexOf(r) >= 0
            ); // some or every
          } else {
            return this.selectedCategories.some(
              (r) => el.properties.category_ids.indexOf(r) >= 0
            ); // some or every
          }
        });
      }
      if (this.geofuzzFilter != null) {
        this.filteredResources = this.filteredResources.filter((el) => {
          return el.properties.geo_fuzz == this.geofuzzFilter;
        });
      }
    },
  },
};
</script>
<style>
@import "~vue-slider-component/theme/default.css";

#fs-map {
  height: 600px;
  min-width: 900px;
  position: relative;
}

.time-selector-map-hidden {
  margin-top: 120px !important;
  margin-bottom: 0px !important;
}

.play-bar {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.and-or {
  margin-top: 10px;
}

.text-search::placeholder {
  color: #bdbdbd;
}

.vue-slider-process {
  background-color: #006464 !important;
}

.vue-slider-dot-tooltip {
  border: 1px solid #006464;
  border-radius: 5px;
}

.vue-slider-dot-tooltip-inner {
  background-color: white !important;
  border-color: #006464 !important;
  cursor: grab;
  color: #006464;
}

.vue-treeselect__multi-value-label,
.vue-treeselect__icon,
.vue-treeselect__multi-value-item {
  color: #006464 !important;
  background-color: #d4e0e0 !important;
}

.dark * .mapboxgl-ctrl {
  background-color: #d4d4d4;
  filter: invert(100%);
}

.dark * .mapboxgl-ctrl select {
  outline: none !important;
}

.dark * .mapboxgl-ctrl-group {
  border-color: #333;
}

.dark * .vue-slider-process {
  background-color: lightgray !important;
}
.dark * .vue-slider-rail {
  //border: 1px solid #515151 !important;
  background-color: #414141;
}

.dark * .vue-slider-dot-tooltip {
  border: 1px solid white;
  border-radius: 5px;
}

.dark * .vue-slider-dot-tooltip-inner {
  background-color: #333 !important;
  border-color: white !important;
  color: white;
}
.dark * .vue-treeselect__control,
.dark * .vue-treeselect--branch-nodes-disabled {
  background-color: #414141 !important;
  color: white !important;
}

.dark * .vue-treeselect__multi-value-label,
.dark * .vue-treeselect__icon,
.dark * .vue-treeselect__multi-value-item {
  background-color: #333 !important;
  color: white !important;
}

.dark * .vue-treeselect__menu:hover,
.dark * .vue-treeselect__option--highlight {
  color: #333;
}

.dark * .vue-treeselect__menu {
  color: white !important;
  background-color: #545454 !important;
}

.dark * .vue-treeselect__multi-value-item:hover,
.dark * .vue-treeselect__option--selected:hover {
  color: #333 !important;
}

.dark * .vue-treeselect__control-arrow {
  fill: lightgray !important;
}

.dark * .vue-treeselect__control-arrow:hover {
  fill: white !important;
}

.dark * .text-search::placeholder {
  color: #bdbdbd;
}

.dark * .and-or {
  margin-top: 10px;
  color: white;
}
select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='lightgrey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 5px;
  fill: "#d3d3d3" !important;
}
.dark * select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='lightgrey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 5px;
  fill: "#d3d3d3" !important;
}
</style>
