//import * as utils from "../Utilities";

export default class RasterLayer {
  constructor(name, url) {
    this.name = name;
    this.url = url;
    this.layers = [`tiles${this.name}`];
  }

  addSource(map) {
    map.addSource(`tiles${this.name}`, {
      type: "raster",
      tiles: [this.url],
    });
  }

  show(map) {
    this.layers.forEach((layer) => {
      map.setLayoutProperty(layer, "visibility", "visible");
    });
  }

  hide(map) {
    this.layers.forEach((layer) => {
      map.setLayoutProperty(layer, "visibility", "none");
    });
  }

  add(map) {
    map.addLayer({
      id: `tiles${this.name}`,
      type: "raster",
      source: `tiles${this.name}`,
      minzoom: 0,
      maxzoom: 22,
    });
  }
}
