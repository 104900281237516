import * as utils from "../Utilities";
import axios from "axios";

class RomanEmpireLayer {
  constructor() {
    this.layers = [
      "romanEmpireProvincesFill",
      "romanEmpireProvincesLabels",
      "romanEmpireProvinces",
    ];
    this.fillData = null;
    this.data = null;
    this.getSource();
  }

  async getSource() {
    const response = await axios.get("/geojson/roman_empire/provinces.geojson");

    this.data = response.data;
    this.fillData = utils.colorPolygons(this.data);
    this.labelData = utils.getCentroids(this.data);
  }

  addSource(map) {
    map.addSource("romanEmpireProvinces", {
      type: "geojson",
      data: this.fillData,
      attribution:
        "Roman empire borders layer by <a href='https://www.klokantech.com/' target='_blank'>© KlokanTech</a> © <a href='https://web.archive.org/web/20191125095339/http://dare.ht.lu.se/' target='_blank'>DARE</a>",
    });
    map.addSource("romanEmpireProvincesLabels", {
      type: "geojson",
      data: "geojson/roman_empire/provinces_label.geojson",
    });
  }

  show(map) {
    this.layers.forEach((layer) => {
      map.setLayoutProperty(layer, "visibility", "visible");
    });
  }

  hide(map) {
    this.layers.forEach((layer) => {
      map.setLayoutProperty(layer, "visibility", "none");
    });
  }

  add(map) {
    map.addLayer({
      id: "romanEmpireProvincesFill",
      type: "fill",
      source: "romanEmpireProvinces",
      paint: {
        "fill-color": { type: "identity", property: "color" },
        "fill-opacity": 0.4,
      },
      layout: {
        visibility: "none",
      },
    });

    map.addLayer({
      id: "romanEmpireProvinces",
      type: "line",
      source: "romanEmpireProvinces",
      layout: {
        visibility: "none",
      },
      paint: {
        "line-color": "rgba(51, 51, 51, 1)",
        "line-width": {
          stops: [
            [4, 1],
            [5, 1.5],
            [6, 1.5],
          ],
        },
      },
    });

    map.addLayer({
      id: "romanEmpireProvincesLabels",
      type: "symbol",
      source: "romanEmpireProvincesLabels",
      minzoom: 3,
      maxzoom: 8,
      layout: {
        "text-size": {
          stops: [
            [3, 10],
            [4, 11],
            [5, 14],
            [6, 14],
            [7, 14],
          ],
        },
        "text-font": ["Avenir, Helvetica, Arial, sans-serif"],
        visibility: "none",
        "text-offset": [0, 0.5],
        "icon-size": 1,
        "text-anchor": "top",
        "text-field": "{name}",
        "text-max-width": 8,
        "text-allow-overlap": true,
      },
      paint: {
        "text-color": "rgba(255, 255, 255, 1)",
        "text-halo-width": 1.4,
        "text-halo-color": "rgba(51, 51, 51, 1)",
        "text-halo-blur": 0,
      },
    });
  }
}

export default new RomanEmpireLayer();
