<template>
  <div>
    <select @change="selectCollection($event)"
      class="mb-2 mt-2 w-full p-1 pl-2 pr-0 h-9 border border-fancy-grey rounded dark:bg-dark-charcoal dark:border dark:border-white dark:text-white appearance-none">
      <option value="none">No Collection</option>
      <!--<optgroup label="Public Collections">-->
      <option v-for="collection in publicCollections" :key="collection.ref" :value="collection.ref">
        {{ collection.name }}
      </option>
      <!--</optgroup>
    <optgroup label="My Collections">
      <option
        v-for="collection in ownCollections"
        :key="collection.ref"
        :value="collection.ref"
      >
        {{ collection.name }}
      </option>
      </optgroup>-->
    </select>
  </div>
</template>

<script>
import * as utils from "../lib/Utilities.js";

export default {
  name: "ResourceImage",
  components: {},
  data() {
    return {
      publicCollections: [],
      ownCollections: [],
      selectedCollection: null,
      colResourceRefs: [],
    };
  },
  mounted() {
    this.getCollections();
  },
  watch: {},
  //mounted() {},
  methods: {
    async selectCollection(event) {
      this.selectedCollection = event.target.value;
      const call = `user=${localStorage.username}&function=do_search&search=!collection${this.selectedCollection}`;
      const signed = await utils.hash(localStorage.sessionKey + call);
      try {
        const response = await this.axios.get(
          `${utils.host}/?${call}&sign=${signed}&authmode=sessionkey`
        );
        this.colResourceRefs = response.data.map((x) => parseInt(x.ref));
        this.$emit("collectionSelected", {
          name: this.selectedCollection,
          refs: this.colResourceRefs,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getCollections() {
      const callPublic = `user=${localStorage.username}&function=search_public_collections&exclude_themes=0`;
      const signedPublic = await utils.hash(
        localStorage.sessionKey + callPublic
      );
      try {
        const response = await this.axios.get(
          `${utils.host}/?${callPublic}&sign=${signedPublic}&authmode=sessionkey`
        );
        this.publicCollections = response.data;
      } catch (error) {
        console.log(error);
      }
      const call = `user=${localStorage.username}&function=get_user_collections`;
      const signed = await utils.hash(localStorage.sessionKey + call);
      try {
        const response = await this.axios.get(
          `${utils.host}/?${call}&sign=${signed}&authmode=sessionkey`
        );
        this.ownCollections = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='lightgrey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 5px;
  fill: "#d3d3d3" !important;
}

.dark * select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='lightgrey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 5px;
  fill: "#d3d3d3" !important;
}
</style>
