import * as utils from "../Utilities";

export default class ResourcePointsLayer {
  constructor() {
    this.layers = ["clusters", "clusterLabels", "resources"];
    this.eventsSet = false;
  }

  addSource(map, features) {
    map.addSource("currentResources", {
      type: "geojson",
      cluster: true,
      //clusterMaxZoom: 14,
      clusterRadius: 24,
      data: {
        type: "FeatureCollection",
        name: "resources",
        features: features,
      },
    });
  }

  show(map) {
    this.layers.forEach((layer) => {
      map.setLayoutProperty(layer, "visibility", "visible");
    });
  }

  hide(map) {
    this.layers.forEach((layer) => {
      map.setLayoutProperty(layer, "visibility", "none");
    });
  }

  add(map, component) {
    map.addLayer({
      id: "clusters",
      type: "circle",
      source: "currentResources",
      filter: ["has", "point_count"],
      layout: {
        visibility: "visible",
      },
      paint: {
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
        "circle-color": [
          "step",
          ["get", "point_count"],
          utils.colorPalette[0],
          10000,
          "#f1f075",
        ],
        "circle-radius": [
          "step",
          ["get", "point_count"],
          8,
          10,
          10,
          70,
          11,
          100,
          13,
          200,
          13,
          400,
          14,
          600,
          15,
        ],
      },
    });

    map.addLayer({
      id: "clusterLabels",
      type: "symbol",
      source: "currentResources",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 9.5,
        visibility: "visible",
      },
      paint: {
        "text-color": "#ffffff",
      },
    });

    map.addLayer({
      id: "resources",
      type: "circle",
      source: "currentResources",
      filter: ["!", ["has", "point_count"]],
      layout: {
        visibility: "visible",
      },
      paint: {
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
        "circle-radius": 4,
        "circle-color": utils.colorPalette[0],
      },
    });
    const displayFeatures = async (features) => {
      component.$emit("clicked", features);
    };
    if (!component.eventsSet) {
      const canvas = map.getCanvasContainer();
      ["resources", "clusters"].forEach((layer) => {
        map.on("mouseenter", layer, function () {
          canvas.style.cursor = "pointer";
        });

        map.on("mouseleave", layer, function () {
          canvas.style.cursor = "";
        });

        map.on("click", layer, async (e) => {
          var features = map.queryRenderedFeatures(e.point, {
            layers: ["resources", "clusters"],
          });
          if (features[0].layer.id == "clusters") {
            var clusterId = features[0].properties.cluster_id,
              point_count = features[0].properties.point_count,
              clusterSource = map.getSource("currentResources");

            // Get all points under a cluster
            clusterSource.getClusterLeaves(
              clusterId,
              point_count,
              0,
              function (err, aFeatures) {
                displayFeatures(aFeatures);
              }
            );
          } else {
            // weird bug, category_ids treated as string when single point
            features[0].properties.category_ids = JSON.parse(
              features[0].properties.category_ids
            );
            displayFeatures(features);
          }
        });
        component.eventsSet = true;
      });
    }
  }
}
