import Vue from "vue";
import App from "./App.vue";
import VueAxios from "vue-axios";
import { setup } from "axios-cache-adapter";
import "flowbite";

// Use `setup` method which gives an axios instance with cache pre-configured
const axiosInstance = setup({
  cache: {
    maxAge: 15 * 60 * 1000,
    exclude: {
      query: false,
    },
  },
});

Vue.use(VueAxios, axiosInstance);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
