import RomanEmpireLayer from "../lib/map_layers/RomanEmpire.js";
import * as utils from "../lib/Utilities";

export class LayerToggle {
  constructor({ component }) {
    this._component = component;
  }

  onAdd(map) {
    this._map = map;
    let _this = this;

    this._btnCluster = document.createElement("button");
    this._btnCluster.className = "fa-solid fa-circle";
    this._btnCluster.type = "button";
    this._btnCluster["aria-label"] = "Toggle clusters";
    this._btnCluster["title"] = "Toggle clusters";
    this._btnCluster.onclick = function () {
      const visibility = map.getLayoutProperty("clusters", "visibility");
      // Toggle layer visibility by changing the layout object's visibility property.
      if (visibility === "visible") {
        _this._component.resourcePointsLayer.hide(_this._map);
        this.className = " fa-regular fa-circle";
        _this._component.clustersVisible = false;
      } else {
        this.className = "fa-solid fa-circle";
        _this._component.resourcePointsLayer.show(_this._map);
        _this._component.clustersVisible = true;
      }
    };

    this._btnHex = document.createElement("button");
    this._btnHex.innerHTML =
      "<i class='fa-regular fa-square fa-rotate-by'></i>";
    this._btnHex.style = "--fa-rotate-angle: 45deg;";
    this._btnHex.type = "button";
    this._btnHex["aria-label"] = "Toggle hex view";
    this._btnHex["title"] = "Toggle hex view";
    this._btnHex.onclick = function () {
      if (_this._component.hexVisible) {
        _this._component.hideHexLayer();
        _this._component.hexVisible = false;

        this.innerHTML = "<i class='fa-regular fa-square fa-rotate-by'></i>";
      } else {
        _this._component.addHexLayer();
        _this._component.hexVisible = true;
        this.innerHTML = "<i class='fa-solid fa-square fa-rotate-by'></i>";
      }
    };

    this._btnHeat = document.createElement("button");
    this._btnHeat.innerHTML = "<i class='fa-regular fa-circle'></i>";
    this._btnHeat.type = "button";
    this._btnHeat["aria-label"] = "Toggle heatmap";
    this._btnHeat["title"] = "Toggle heatmap";
    this._btnHeat.onclick = function () {
      if (_this._component.heatVisible) {
        _this._component.hideHeatLayer();
        _this._component.heatVisible = false;
        this.innerHTML = "<i class='fa-regular fa-circle'></i>";
      } else {
        _this._component.addHeatLayer();
        _this._component.heatVisible = true;
        this.innerHTML = "<i class='fa-solid fa-record-vinyl'></i>";
      }
    };

    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl text-base";
    this._container.appendChild(this._btnCluster);
    this._container.appendChild(this._btnHex);
    this._container.appendChild(this._btnHeat);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

export class SavePic {
  constructor() {}

  onAdd(map) {
    this._map = map;
    let _this = this;

    this._btn = document.createElement("button");
    this._btn.className = "fa-solid fa-image";
    this._btn.type = "button";
    this._btn["aria-label"] = "Save map as picture";
    this._btn["title"] = "Save map as picture";
    this._btn.onclick = function () {
      utils.takeScreenshot(_this._map).then(function (image) {
        utils.saveContent(image, "heavens_map.png");
      });
    };

    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl text-base";
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

export class BaseMapSelect {
  constructor({ component }) {
    this._component = component;
  }

  onAdd(map) {
    this._map = map;
    let _this = this;
    this._layerSelect = document.createElement("select");
    this._layerSelect.className =
      "text-xs mb-1 mt-1 ml-2 mr-2 w-44 dark:bg-neutral-300 focus:ring-0 border-transparent focus:border-transparent";
    this._component.baseMaps.forEach((element, index) => {
      var opt = document.createElement("option");
      opt.value = index;
      if (index == this._component.currentBaseMapIndex) {
        opt.selected = "selected";
      }
      opt.innerHTML = element.name;
      this._layerSelect.appendChild(opt);
    });

    this._layerSelect.onchange = function () {
      _this._component.hideHexLayer();
      _this._component.hideHeatLayer();
      _this._component.currentBaseMapIndex = this.value;
      _this._map.setStyle(_this._component.baseMaps[this.value].url, {
        diff: false,
      });
    };

    this._container = document.createElement("div");
    this._container.className =
      "mapboxgl-ctrl-group mapboxgl-ctrl text-base p-0";
    this._container.appendChild(this._layerSelect);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

export class OverlaySelect {
  constructor({ component }) {
    this._component = component;
  }

  onAdd(map) {
    this._map = map;
    let _this = this;
    this._layerSelect = document.createElement("select");
    this._layerSelect.className =
      "text-xs mb-1 mt-1 ml-2 mr-2 w-44 dark:bg-neutral-300 focus:ring-0  border-transparent focus:border-transparent";
    var firstOption = document.createElement("option");
    firstOption.value = 0;
    firstOption.innerHTML = "no overlay";
    this._layerSelect.appendChild(firstOption);
    this._component.overlayLayers.forEach((element) => {
      if (element.id != "HistoricalBorders") {
        var opt = document.createElement("option");
        opt.value = element.id;
        opt.innerHTML = element.name;
        this._layerSelect.appendChild(opt);
        if (
          element.id == "RomanEmpire" &&
          _this._component.romanEmpireVisible
        ) {
          opt.selected = "selected";
        }
      }
    });
    _this._component.historicalLayers.forEach((layer) => {
      var optYear = document.createElement("option");
      optYear.value = layer.year;
      if (layer.visible) {
        optYear.selected = "selected";
      }
      optYear.innerHTML = `world borders, year ${layer.year}`;
      this._layerSelect.appendChild(optYear);
    });

    this._layerSelect.onchange = function () {
      RomanEmpireLayer.hide(_this._map);
      RomanEmpireLayer.visible = false;
      for (const historicalLayer of _this._component.historicalLayers) {
        historicalLayer.hide(_this._map);
        historicalLayer.visible = false;
      }
      if (this.value == "RomanEmpire") {
        RomanEmpireLayer.show(_this._map);
        _this._component.romanEmpireVisible = true;
      }
      for (const historicalLayer of _this._component.historicalLayers) {
        if (this.value == historicalLayer.year) {
          historicalLayer.visible = true;
          historicalLayer.show(_this._map);
        }
      }
    };

    this._container = document.createElement("div");
    this._container.className =
      "mapboxgl-ctrl-group mapboxgl-ctrl text-base p-0";
    this._container.appendChild(this._layerSelect);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
